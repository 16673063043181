<script setup lang="ts">
import { RouterView, useRouter } from 'vue-router'
import { provide, ref } from 'vue'

import Loading from '@/components/Loading.vue'
import Toast from '@/components/Toast.vue'
import IconTick from '@/components/icons/IconTick.vue'

import { storeToRefs } from 'pinia'
import { useTaskStore } from '@/stores/task'
import { useNavigationStore } from '@/stores/navigation'
import { useAuthStore } from '@/stores/auth'
import { AsyncOperationState } from '@/stores/lib/AsyncOperationState'
import Message from './components/Message.vue'

const navigationStore = useNavigationStore()
const authStore = useAuthStore()
const router = useRouter()

navigationStore.load()

const routerIsReady = ref<boolean>(false)
router.isReady().then(() => {
  routerIsReady.value = true
})

const { main, mainMobile, footer, navSettings } = storeToRefs(navigationStore)

provide('MAIN_NAV', main)
provide('NAV_SETTINGS', navSettings)
provide('MAIN_NAV_MOBILE', mainMobile)
provide('FOOTER_NAV', footer)

const taskStore = useTaskStore()
</script>

<template>
  <div
    class="AppWarningBanner"
    v-if="authStore.user && authStore.user.impersonating"
  >
    <Message
      mode="warning"
      pointer-direction="vertical"
      pointer-position="topleft"
    >
      <p>
        <b>Warning!</b>
        You are viewing the app as someone else. Be careful! Any changes you
        make will be applied to this persons's account. This session will last a
        maximum of 20 minutes.
      </p>
    </Message>
  </div>
  <template v-if="routerIsReady">
    <RouterView v-slot="{ Component }">
      <Transition appear mode="out-in">
        <component :is="Component" />
      </Transition>
    </RouterView>
  </template>
  <template v-else>
    <div class="AppLoadingState">
      <Loading />
    </div>
  </template>
  <Toast
    :mode="[
      taskStore.task.state === AsyncOperationState.ERRORED ? 'error' : 'mid',
    ]"
    :has-shake="taskStore.task.state === AsyncOperationState.ERRORED"
    :has-close="taskStore.task.state === AsyncOperationState.ERRORED"
    @close="taskStore.task && taskStore.clearTask(taskStore.task.id)"
    v-if="taskStore.task"
    :loading="taskStore.task.state === AsyncOperationState.IN_PROGRESS"
  >
    <template v-if="taskStore.task.state === AsyncOperationState.DONE" #icon>
      <IconTick :animate="true" />
    </template>
    {{ taskStore.task.name }}
  </Toast>
</template>

<style>
@import '@/assets/css/base.css';

.AppLoadingState {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.AppWarningBanner {
  background-color: var(--color-warning-100);
  height: 5px;
  position: relative;
  z-index: 100;

  & .Message {
    display: none;
  }

  &:hover .Message {
    display: block;
    z-index: 1;
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
  }
}
</style>
