<script setup lang="ts">
import IconClose from '@/assets/icons/close.svg?component'
import { formatMoneyWithCurrency } from '@/lib/formatters/money'
import { usePageOverlay } from '@/lib/pageOverlay'
import type { PredictiveSearchResults, Product } from '@/services/storefront'
import { computed, onMounted, ref, watch } from 'vue'

const props = defineProps<{
  query: string
  isShowing: boolean
  results: PredictiveSearchResults['data']['predictiveSearch'] | null
}>()

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'change', query: string): void
  (e: 'select', index: number): void
}>()

const q = ref(props.query)
const rootEl = ref<HTMLDivElement | null>(null)
watch(
  () => props.query,
  (value) => {
    q.value = value
  }
)

const searchInput = ref<HTMLInputElement | null>(null)

watch(q, async () => {
  cursorIndex.value = -1
  emit('change', q.value)
})

const resultsLength = computed(() => {
  if (props.results) {
    return (
      props.results.articles.length +
      props.results.products.length +
      props.results.pages.length +
      props.results.queries.length
    )
  } else {
    return 0
  }
})

onMounted(() => {
  if (searchInput.value) {
    searchInput.value.addEventListener('keydown', (e) => {
      switch (e.key) {
        case 'ArrowDown':
          cursorIndex.value = Math.min(
            cursorIndex.value + 1,
            resultsLength.value
          )
          e.preventDefault()
          break
        case 'ArrowUp':
          cursorIndex.value = Math.max(cursorIndex.value - 1, -1)
          e.preventDefault()
          break
        case 'Enter':
          if (cursorIndex.value >= 0) {
            e.preventDefault()
            e.stopPropagation()
            emit('select', cursorIndex.value)
            return false
          }
          break
      }
      return
    })
  }
})

watch(
  () => props.isShowing,
  (value, oldValue) => {
    if (value) {
      show()
      if (value !== oldValue) {
        setTimeout(() => searchInput.value?.focus(), 100)
      }
    } else {
      hide()
    }
  }
)

const { show, hide } = usePageOverlay(
  (e) => {
    if (document.querySelector('.PageOverlay') === e.target) {
      emit('close')
    }
  },
  (e) => {
    if (e.key === 'Escape') {
      emit('close')
    }
  }
)

const cursorIndex = ref(-1)
watch(
  cursorIndex,
  () => {
    if (rootEl.value) {
      rootEl.value.querySelector('.is-focused')?.scrollIntoView({
        block: 'nearest',
      })
    }
  },
  { flush: 'post' }
)

const getPrice = (product: Product) => {
  const sellingPlans =
    product.sellingPlanGroups && product.sellingPlanGroups.edges[0]
      ? product.sellingPlanGroups.edges[0].node.sellingPlans
      : null
  const price = parseFloat(product.variants.edges[0].node.price.amount) * 100
  return sellingPlans
    ? price -
        (price / 100) *
          sellingPlans.edges[0].node.priceAdjustments[0].adjustmentValue
            .adjustmentPercentage
    : price
}

const onlineStoreUrlWithTrackingParams = (entity: {
  onlineStoreUrl: string
  trackingParameters: string | null
}) => {
  const url = new URL(entity.onlineStoreUrl)
  if (entity.trackingParameters) {
    new URLSearchParams(entity.trackingParameters).forEach((value, key) => {
      url.searchParams.append(key, value)
    })
  }
  return url.toString()
}
</script>

<template>
  <div class="PredictiveSearch" :class="{ 'is-open': isShowing }" ref="rootEl">
    <div class="PredictiveSearch__Inner">
      <div class="PredictiveSearch__Form">
        <form action="/search" method="get" role="search">
          <label for="q" class="PredictiveSearch__Form__Label sr-only">
            Search
          </label>
          <input
            type="search"
            name="q"
            autocomplete="off"
            autocapitalize="none"
            autofocus
            placeholder="Search for…"
            class="PredictiveSearch__Form__Input"
            ref="searchInput"
            v-model="q"
          />
        </form>
        <button
          class="PredictiveSearch__Form__Buton PredictiveSearch__Form__Close"
          aria-label="{{ 'general.search.search_close_label' | t }}"
          @click="emit('close')"
        >
          <IconClose></IconClose>
        </button>
      </div>
      <div class="PredictiveSearch__Results" v-if="query.length > 0">
        <div
          class="PredictiveSearch__Results__Queries"
          v-if="results && results.queries.length > 0"
        >
          <p class="PredictiveSearch__Results__SectionLabel">
            Suggested search terms
          </p>
          <ul>
            <li
              class="PredictiveSearch__Result"
              v-for="(suggestedQuery, i) in results.queries"
              :key="suggestedQuery.text"
              :class="{ 'is-focused': cursorIndex === i }"
              @click="q = suggestedQuery.text"
            >
              {{ suggestedQuery.text }}
            </li>
          </ul>
        </div>
        <div
          class="PredictiveSearch__Results__Products"
          v-if="results && results.products.length > 0"
        >
          <p class="PredictiveSearch__Results__SectionLabel">Products</p>
          <ul>
            <li
              v-for="(product, i) in results?.products"
              class="PredictiveSearch__Result"
              :key="product.id"
              :class="{
                'is-focused': results?.queries.length + i === cursorIndex,
              }"
            >
              <a :href="onlineStoreUrlWithTrackingParams(product)">
                <img
                  v-if="product.images.edges.length > 0"
                  :src="product.images.edges[0].node.small"
                  :height="product.images.edges[0].node.height"
                  :width="product.images.edges[0].node.width"
                  :alt="product.title"
                />
                <div class="PredictiveSearch__ResultCopy">
                  <p>
                    <strong>{{ product.title }}</strong>
                  </p>
                  <p>
                    {{
                      formatMoneyWithCurrency(
                        (getPrice(product) / 100).toString()
                      )
                    }}
                  </p>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div
          class="PredictiveSearch__Results__Pages"
          v-if="results && results.pages.length > 0"
        >
          <p class="PredictiveSearch__Results__SectionLabel">Pages</p>
          <ul>
            <li
              class="PredictiveSearch__Result"
              v-for="(page, i) in results?.pages"
              :key="page.title"
              :class="{
                'is-focused':
                  results?.queries.length + results?.products.length + i ===
                  cursorIndex,
              }"
            >
              <a :href="onlineStoreUrlWithTrackingParams(page)">
                <p>
                  <strong>{{ page.title }}</strong>
                </p>
                <p v-if="page.bodySummary">{{ page.bodySummary }}</p>
                <p v-else-if="page.seo.description">
                  {{ page.seo.description }}
                </p>
              </a>
            </li>
          </ul>
        </div>
        <div
          class="PredictiveSearch__Results__Articles"
          v-if="results && results.articles.length > 0"
        >
          <p class="PredictiveSearch__Results__SectionLabel">Articles</p>
          <ul>
            <li
              class="PredictiveSearch__Result"
              v-for="(article, i) in results?.articles"
              :key="article.title"
              :class="{
                'is-focused':
                  results?.queries.length +
                    results?.products.length +
                    results?.pages.length +
                    i ===
                  cursorIndex,
              }"
            >
              <a :href="onlineStoreUrlWithTrackingParams(article)">
                <p>
                  <strong>{{ article.title }}</strong>
                </p>
                <p v-if="article.excerpt">
                  {{ article.excerpt }}
                </p>
                <p v-else-if="article.seo.description">
                  {{ article.seo.description }}
                </p>
              </a>
            </li>
          </ul>
        </div>
        <div class="PredictiveSearch__ResultDivider"></div>
        <div>
          <a
            :href="`/search?q=${encodeURIComponent(query)}`"
            class="PredictiveSearch__Result PredictiveSearch__Result--all"
            :class="{
              'is-focused': resultsLength === cursorIndex,
            }"
          >
            <span>See all results for "{{ query }}"</span><span>→</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.PredictiveSearch {
  position: fixed;
  top: 5vh;
  left: 5px;
  right: 5px;
  max-height: 90vh;
  z-index: 21;
  opacity: 0;
  visibility: hidden;
  transition: all var(--global-transition);

  &.is-open {
    opacity: 1;
    visibility: visible;
  }

  @media (--mq-landscape-tablet-and-up) {
    left: 50%;
    transform: translateX(-50%);
    width: 95%;
    max-width: 1144px;
  }
}

.PredictiveSearch__Inner {
  display: flex;
  flex-direction: column;
  max-height: inherit;
  background-color: var(--color-white);
  padding: 14px 24px;
  text-align: left;
  border-radius: var(--radius-small);
  box-shadow: 2px 0 10px var(--color-shadow-30),
    -2px 0 10px var(--color-shadow-30);

  @media (--mq-landscape-tablet-and-up) {
    padding: var(--grid-gap);
  }
}

.PredictiveSearch__Form {
  position: relative;

  & form {
    margin-right: 45px;
    position: relative;
  }
}

input[type='search'].PredictiveSearch__Form__Input {
  font-size: var(--font-size--base);
  line-height: 1;
  border: 0;
  max-width: none;
  width: 100%;
  padding: 0 40px 0 0;
  transition: all var(--global-transition);
  outline: 0;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }

  &:focus {
    outline: 0;
  }

  @media (--mq-tablet-and-up) {
    font-family: var(--font-family-domaine);
    font-size: var(--font-size--h4);
    line-height: var(--line-heigh--h4);
  }
}

.PredictiveSearch__Form__Buton {
  border: 0;
  background-color: transparent;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-forest-100);
  cursor: pointer;
  position: absolute;
  transform: translateY(-50%);
  border-radius: 50px;
  transition: all var(--global-transition);

  & .Icon {
    width: 18px;
    height: 18px;
    transition: all var(--global-transition);
  }

  &:hover {
    color: var(--color-forest-100);
    background-color: var(--color-lichen-150);
  }
}

.PredictiveSearch__Form__Close {
  top: 50%;
  right: 0;
  width: 36px;
  height: 36px;
  padding: 10px;
}

.PredictiveSearch__Results {
  display: flex;
  flex-direction: column;
  gap: var(--grid-gap);
  margin: 0 -10px;
  padding: 14px 0 0;
  overflow: auto;
}

.PredictiveSearch__ResultCopy {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  justify-content: center;

  & p {
    margin: 0;
  }
}

.PredictiveSearch__Results__SectionLabel {
  color: var(--color-forest-50);
  font-size: var(--font-size--small);
  padding: 0 10px;
  margin: 0 0 5px;
}

.PredictiveSearch__Results__Queries {
  & ul {
    margin: 0;
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: calc(var(--grid-gap) / 4);
    justify-content: flex-start;
    align-items: flex-start;
  }

  & li {
    padding: 6px 10px;
    color: var(--color-ocean-100);
  }
}

.PredictiveSearch__Results__Products {
  & ul {
    margin: 0;
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: calc(var(--grid-gap) / 4);
    justify-content: stretch;
    align-items: flex-start;
  }

  & a {
    display: flex;
    gap: calc(var(--grid-gap) / 2);
    color: var(--color-forest-100);
  }

  & img {
    border-radius: var(--radius-large);
    width: 100px;
    flex-grow: 0;
    height: auto;
  }

  & li {
    width: 100%;
  }
}

.PredictiveSearch__Result {
  padding: 10px;
  border-radius: var(--radius-large);
  transition-property: background-color, box-shadow;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
  cursor: pointer;

  & a {
    color: var(--color-forest-100);
  }

  &:hover {
    background-color: var(--color-lichen-150);
    text-decoration: none;

    & a {
      color: var(--color-forest-100);
      text-decoration: none;
    }
  }

  &.is-focused {
    background-color: var(--color-lichen-100);
    box-shadow: 0 0 0 2px inset var(--color-ocean-100),
      0 0 0 3px inset var(--color-white);
  }

  &.PredictiveSearch__Result--all {
    display: flex;
    padding: 10px;
    border-radius: var(--radius-large);

    & span:first-child {
      flex-grow: 1;
    }
  }
}

.PredictiveSearch__Results__Pages,
.PredictiveSearch__Results__Articles {
  & ul {
    margin: 0;
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: calc(var(--grid-gap) / 4);
    justify-content: flex-start;
    align-items: flex-start;
  }

  & li {
    padding: 10px;
    border-radius: var(--radius-large);

    & a {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    & p {
      margin: 0;
    }
  }
}

.PredictiveSearch__ResultDivider {
  border-top: 1px solid var(--color-lichen-150);
  margin: 0 10px -10px;
}
</style>
